<template>
  <!--服务商首页-->
  <div style="padding: 20px;">
    <div class="title" style="display: flex;align-items: center;">
      {{ $route.meta.title }}
    </div>
      <!--<div style="font-size: 20px;margin-bottom: 10px;">-->
      <!--  <span>当前会员等级: {{memberinfo.title}}</span>-->
      <!--  <el-button type="primary" style="margin-left: 10px;" @click="ToVip()">升级</el-button>-->
      <!--</div>-->
      <!--  统计-->
      <div class="contentshouye">
        <!--<Row :gutter="16" style="margin-left: -12px; margin-right: -12px;">-->
        <!--  <Col v-bind="grid" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">-->
        <!--    <div class="content_kshouye">-->
        <!--      <img class="content_kshouye_img" src="@/assets/images/home/servicehome/icon1.png" alt="">-->
        <!--      <div class="content_kshouye_div">-->
        <!--        <div class="content_kshouye_div_f1">-->
        <!--          <span style="vertical-align: middle;">员工总数</span>-->
        <!--          &lt;!&ndash;<div class="wenhaoimg"></div>&ndash;&gt;-->
        <!--        </div>-->
        <!--        <div class="content_kshouye_div_f2">-->
        <!--          <div>-->
        <!--            {{header_statics.staffCount}}-->
        <!--          </div>-->
        <!--          &lt;!&ndash;<div class="content_kshouye_div_f2_f">&ndash;&gt;-->
        <!--          &lt;!&ndash;  <div>&ndash;&gt;-->
        <!--          &lt;!&ndash;    较前一日&ndash;&gt;-->
        <!--          &lt;!&ndash;    <span>&ndash;&gt;-->
        <!--          &lt;!&ndash;      <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/shangsheng.png" alt="">&ndash;&gt;-->
        <!--          &lt;!&ndash;      <span style="color: #ec554d;">3%</span>&ndash;&gt;-->
        <!--          &lt;!&ndash;    </span>&ndash;&gt;-->
        <!--          &lt;!&ndash;  </div>&ndash;&gt;-->
        <!--          &lt;!&ndash;  <div>&ndash;&gt;-->
        <!--          &lt;!&ndash;    较上一周&ndash;&gt;-->
        <!--          &lt;!&ndash;    <span>&ndash;&gt;-->
        <!--          &lt;!&ndash;      <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/xiajiang.png" alt="">&ndash;&gt;-->
        <!--          &lt;!&ndash;      <span style="color: #49d2bc;">3%</span>&ndash;&gt;-->
        <!--          &lt;!&ndash;    </span>&ndash;&gt;-->
        <!--          &lt;!&ndash;  </div>&ndash;&gt;-->
        <!--          &lt;!&ndash;</div>&ndash;&gt;-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->
        <!--  </Col>-->
        <!--  <Col v-bind="grid" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">-->
        <!--    <div class="content_kshouye">-->
        <!--      <img class="content_kshouye_img" src="@/assets/images/home/servicehome/icon2.png" alt="">-->
        <!--      <div class="content_kshouye_div">-->
        <!--        <div class="content_kshouye_div_f1">-->
        <!--          <span style="vertical-align: middle;">订单总额</span>-->
        <!--          &lt;!&ndash;<div class="wenhaoimg"></div>&ndash;&gt;-->
        <!--        </div>-->
        <!--        <div class="content_kshouye_div_f2">-->
        <!--          <div>-->
        <!--            ￥{{header_statics.orderMoney}}-->
        <!--          </div>-->
        <!--          &lt;!&ndash;<div class="content_kshouye_div_f2_f">&ndash;&gt;-->
        <!--          &lt;!&ndash;  <div>&ndash;&gt;-->
        <!--          &lt;!&ndash;    较前一日&ndash;&gt;-->
        <!--          &lt;!&ndash;    <span>&ndash;&gt;-->
        <!--          &lt;!&ndash;      <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/shangsheng.png" alt="">&ndash;&gt;-->
        <!--          &lt;!&ndash;      <span style="color: #ec554d;">3%</span>&ndash;&gt;-->
        <!--          &lt;!&ndash;    </span>&ndash;&gt;-->
        <!--          &lt;!&ndash;  </div>&ndash;&gt;-->
        <!--          &lt;!&ndash;  <div>&ndash;&gt;-->
        <!--          &lt;!&ndash;    较上一周&ndash;&gt;-->
        <!--          &lt;!&ndash;    <span>&ndash;&gt;-->
        <!--          &lt;!&ndash;      <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/xiajiang.png" alt="">&ndash;&gt;-->
        <!--          &lt;!&ndash;      <span style="color: #49d2bc;">3%</span>&ndash;&gt;-->
        <!--          &lt;!&ndash;    </span>&ndash;&gt;-->
        <!--          &lt;!&ndash;  </div>&ndash;&gt;-->
        <!--          &lt;!&ndash;</div>&ndash;&gt;-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->
        <!--  </Col>-->
        <!--  &lt;!&ndash;<Col v-bind="grid" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">&ndash;&gt;-->
        <!--  &lt;!&ndash;  <div class="content_kshouye">&ndash;&gt;-->
        <!--  &lt;!&ndash;    <img class="content_kshouye_img" src="@/assets/images/home/servicehome/icon3.png" alt="">&ndash;&gt;-->
        <!--  &lt;!&ndash;    <div class="content_kshouye_div">&ndash;&gt;-->
        <!--  &lt;!&ndash;      <div class="content_kshouye_div_f1">&ndash;&gt;-->
        <!--  &lt;!&ndash;        <span style="vertical-align: middle;">累计投融资总额</span>&ndash;&gt;-->
        <!--  &lt;!&ndash;        <div class="wenhaoimg"></div>&ndash;&gt;-->
        <!--  &lt;!&ndash;      </div>&ndash;&gt;-->
        <!--  &lt;!&ndash;      <div class="content_kshouye_div_f2">&ndash;&gt;-->
        <!--  &lt;!&ndash;        <div>&ndash;&gt;-->
        <!--  &lt;!&ndash;          ￥{{header_statics.financingMoney}}&ndash;&gt;-->
        <!--  &lt;!&ndash;        </div>&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;<div class="content_kshouye_div_f2_f">&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;  <div>&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;    较前一日&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;    <span>&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;      <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/shangsheng.png" alt="">&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;      <span style="color: #ec554d;">3%</span>&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;    </span>&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;  </div>&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;  <div>&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;    较上一周&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;    <span>&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;      <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/xiajiang.png" alt="">&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;      <span style="color: #49d2bc;">3%</span>&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;    </span>&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;  </div>&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;        &lt;!&ndash;</div>&ndash;&gt;&ndash;&gt;-->
        <!--  &lt;!&ndash;      </div>&ndash;&gt;-->
        <!--  &lt;!&ndash;    </div>&ndash;&gt;-->
        <!--  &lt;!&ndash;  </div>&ndash;&gt;-->
        <!--  &lt;!&ndash;</Col>&ndash;&gt;-->
        <!--  <Col v-bind="grid" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">-->
        <!--    <div class="content_kshouye">-->
        <!--      <img class="content_kshouye_img" src="@/assets/images/home/servicehome/icon4.png" alt="">-->
        <!--      <div class="content_kshouye_div">-->
        <!--        <div class="content_kshouye_div_f1">-->
        <!--          <span style="vertical-align: middle;">累计订单数</span>-->
        <!--          &lt;!&ndash;<div class="wenhaoimg"></div>&ndash;&gt;-->
        <!--        </div>-->
        <!--        <div class="content_kshouye_div_f2">-->
        <!--          <div>-->
        <!--            {{header_statics.orderCount}}-->
        <!--          </div>-->
        <!--          &lt;!&ndash;<div class="content_kshouye_div_f2_f">&ndash;&gt;-->
        <!--          &lt;!&ndash;  <div>&ndash;&gt;-->
        <!--          &lt;!&ndash;    较前一日&ndash;&gt;-->
        <!--          &lt;!&ndash;    <span>&ndash;&gt;-->
        <!--          &lt;!&ndash;      <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/shangsheng.png" alt="">&ndash;&gt;-->
        <!--          &lt;!&ndash;      <span style="color: #ec554d;">3%</span>&ndash;&gt;-->
        <!--          &lt;!&ndash;    </span>&ndash;&gt;-->
        <!--          &lt;!&ndash;  </div>&ndash;&gt;-->
        <!--          &lt;!&ndash;  <div>&ndash;&gt;-->
        <!--          &lt;!&ndash;    较上一周&ndash;&gt;-->
        <!--          &lt;!&ndash;    <span>&ndash;&gt;-->
        <!--          &lt;!&ndash;      <img style="margin-left: 7px; margin-right: 7px" src="@/assets/images/home/icon/xiajiang.png" alt="">&ndash;&gt;-->
        <!--          &lt;!&ndash;      <span style="color: #49d2bc;">3%</span>&ndash;&gt;-->
        <!--          &lt;!&ndash;    </span>&ndash;&gt;-->
        <!--          &lt;!&ndash;  </div>&ndash;&gt;-->
        <!--          &lt;!&ndash;</div>&ndash;&gt;-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->
        <!--  </Col>-->
        <!--</Row>-->
      </div>

      <!-- 订单数据总览  企业数据总览 -->
      <div class="Overview">
        <el-row :gutter="15">
          <el-col :span="12">
            <el-card shadow="never">
                <div class="Overview_t">
                  <div class="Overview_t_icon"></div>
                  <div class="Overview_t_text">
                    订单数据总览
                  </div>
                </div>
                <div class="Overview_c">
                  <Row justify="space-around">
                    <Col v-bind="grid10" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                      <div class="Overview_c_k">
                        <div class="Overview_c_k_f1">本年度总订单额</div>
                        <div class="Overview_c_k_f2">{{order_data_statics.TotalMoneyYear}}</div>
                      </div>
                    </Col>
                    <Col v-bind="grid10" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                      <div class="Overview_c_k">
                        <div class="Overview_c_k_f1">本年度总订单量</div>
                        <div class="Overview_c_k_f2">{{order_data_statics.TotalCountYear}}</div>
                      </div>
                    </Col>
                  </Row>
                  <Row justify="space-around">
                    <Col v-bind="grid10" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                      <div class="Overview_c_k">
                        <div class="Overview_c_k_f1">本年度已结算订单额</div>
                        <div class="Overview_c_k_f2">{{order_data_statics.TotalSettledMoneyYear}}</div>
                      </div>
                    </Col>
                    <Col v-bind="grid10" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                      <div class="Overview_c_k">
                        <div class="Overview_c_k_f1">本年度已结算订单量</div>
                        <div class="Overview_c_k_f2">{{order_data_statics.TotalSettledCountYear}}</div>
                      </div>
                    </Col>
                  </Row>
                </div>
            </el-card>
          </el-col>

          <el-col :span="12">
            <el-card shadow="never">
                <div class="Overview_t">
                  <div class="Overview_t_icon"></div>
                  <div class="Overview_t_text">
                    企业数据总览
                  </div>
                </div>
                <div class="Overview_c">
                  <Row justify="space-around">
                    <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                      <div class="Overview_c_k">
                        <div class="Overview_c_k_f1">待接单</div>
                        <div class="Overview_c_k_f2">{{firm_data_statics.join_order_count}}</div>
                      </div>
                    </Col>
                    <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                      <div class="Overview_c_k">
                        <div class="Overview_c_k_f1">待派单 </div>
                        <div class="Overview_c_k_f2">{{firm_data_statics.send_order_count}}</div>
                      </div>
                    </Col>
                    <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                      <div class="Overview_c_k">
                        <div class="Overview_c_k_f1">待司机接单</div>
                        <div class="Overview_c_k_f2">{{firm_data_statics.driver_order_count}}</div>
                      </div>
                    </Col>
                    <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                      <div class="Overview_c_k">
                        <div class="Overview_c_k_f1">待完成</div>
                        <div class="Overview_c_k_f2">{{firm_data_statics.finish_order_count}}</div>
                      </div>
                    </Col>
                    <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                      <div class="Overview_c_k">
                        <div class="Overview_c_k_f1">待审核</div>
                        <div class="Overview_c_k_f2">{{firm_data_statics.check_order_count}}</div>
                      </div>
                    </Col>
                    <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                      <div class="Overview_c_k">
                        <div class="Overview_c_k_f1">待复审 </div>
                        <div class="Overview_c_k_f2">{{firm_data_statics.recheck_order_count}}</div>
                      </div>
                    </Col>
                    <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                      <div class="Overview_c_k">
                        <div class="Overview_c_k_f1">待确认</div>
                        <div class="Overview_c_k_f2">{{firm_data_statics.affirm_order_count}}</div>
                      </div>
                    </Col>
                    <Col v-bind="grid1" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">
                      <div class="Overview_c_k">
                        <div class="Overview_c_k_f1">异常订单</div>
                        <div class="Overview_c_k_f2">{{firm_data_statics.abnormal_order_count}}</div>
                      </div>
                    </Col>
                  </Row>
                </div>
            </el-card>
          </el-col>
        </el-row>

      </div>


    <!--  常用功能入口-->
    <!--<Card :bordered="false" dis-hover class="ivu-mt" style="margin-top: 20px;" v-if="loginUser.rank != 'teamwork'">-->
    <!--  <div class="Commonfunctions">-->
    <!--    <div class="Commonfunctions_t">-->
    <!--      <div class="Commonfunctions_t_icon"></div>-->
    <!--      <div class="Commonfunctions_t_text">-->
    <!--        常用功能入口-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="Commonfunctions_c">-->
    <!--      <grid-menu />-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</Card>-->

    <!--  订单数量汇总 -->
    <!--<Card :bordered="false" dis-hover class="ivu-mt" style="margin-top: 20px;">-->
    <!--  <div class="order">-->
    <!--    <div class="order_t">-->
    <!--      <div style="display: flex; align-items: center;">-->
    <!--        <div class="order_t_icon"></div>-->
    <!--        <div class="order_t_text">-->
    <!--          <span>订单数量汇总</span>-->
    <!--        </div>-->
    <!--        <div class="anniu">-->
    <!--          <div v-for="(item,index) in tab" :key="index" class="div" :class="{div1:index==isShow}" @click='change(item,index)'>-->
    <!--            {{item.name}}-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--      &lt;!&ndash;<div class="datexuanzeqi">&ndash;&gt;-->
    <!--      &lt;!&ndash;  <DatePicker :value="value2" format="yyyy/MM/dd" type="daterange" placement="bottom-end" placeholder="Select date" style="width: 200px"></DatePicker>&ndash;&gt;-->
    <!--      &lt;!&ndash;</div>&ndash;&gt;-->
    <!--    </div>-->
    <!--    <div class="order_c">-->
    <!--      <Row justify="space-around">-->
    <!--        <Col v-bind="ordergrid_l" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">-->
    <!--          <div class="order_img">-->
    <!--            <div class="order_img_t">昨日订单数</div>-->
    <!--            <div class="order_img_s">{{order_count_charts.last_week}}</div>-->
    <!--          </div>-->
    <!--        </Col>-->
    <!--        <Col v-bind="ordergrid_r" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">-->
    <!--          <echarts-from-->
    <!--            ref="userChart"-->
    <!--            :echartsTitle="line"-->
    <!--            :infoList="infoList"-->
    <!--            :series="series"-->
    <!--            v-if="infoList"-->
    <!--          ></echarts-from>-->
    <!--        </Col>-->
    <!--      </Row>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</Card>-->

    <!--  订单金额统计 -->
    <!--<Card :bordered="false" dis-hover class="ivu-mt" style="margin-top: 20px;">-->
    <!--  <div class="order">-->
    <!--    <div class="order_t">-->
    <!--      <div style="display: flex; align-items: center;">-->
    <!--        <div class="order_t_icon"></div>-->
    <!--        <div class="order_t_text">-->
    <!--          <span>订单金额统计</span>-->
    <!--        </div>-->
    <!--        <div class="anniu">-->
    <!--          <div v-for="(item,index) in moneytab" :key="index" class="div" :class="{moneydiv:index==moneyisShow}" @click='moneychange(item,index)'>-->
    <!--            {{item.name}}-->
    <!--          </div>-->
    <!--        </div>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <div class="order_c">-->
    <!--      <Row justify="space-around">-->
    <!--        <Col v-bind="ordergrid_l" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">-->
    <!--          <div class="order_img1">-->
    <!--            <div class="order_img_t1">昨日订单金额</div>-->
    <!--            <div class="order_img_s1">{{order_money_statics.last_week}}</div>-->
    <!--          </div>-->
    <!--        </Col>-->
    <!--        <Col v-bind="ordergrid_r" class="ivu-mb" style="padding-left: 8px; padding-right: 8px; margin-top: 10px; margin-bottom: 10px;">-->
    <!--          <echarts-fr-->
    <!--            ref="userChart"-->
    <!--            :echartsTitle="line"-->
    <!--            :infoListse="infoListse"-->
    <!--            :series="series1"-->
    <!--            v-if="infoList"></echarts-fr>-->
    <!--        </Col>-->
    <!--      </Row>-->
    <!--    </div>-->
    <!--  </div>-->
    <!--</Card>-->

    <el-card shadow="never" style="margin-top: 20px;">
      <el-row :gutter="15">
        <el-col :span="4">
          <div style="display: flex;align-items: center;">
            <div class='xiaoshudao'></div>
            <div>
            <span class='titlestyle'>订单数量统计</span>
            </div>
          </div>
        </el-col>
        <el-col :span="20">
          <div>
            <el-row :gutter="15">
              <el-col :span="6">
                <span>客户:</span>
                <el-select
                    @change="selectclient"
                    style="width: 150px !important;margin-left: 10px;"
                    clearable
                    v-model="channel_id"
                    placeholder="请选择客户"
                    class="ele-fluid">
                  <el-option v-for="(item) in client" :label="item.channel_name + item.channel_type" :value="parseInt(item.id)"/>
                </el-select>
              </el-col>
              <el-col :span="8">
                <el-date-picker
                    @change="selecttime"
                    unlink-panels
                    v-model="time"
                    type="daterange"
                    placeholder="请选择"
                    value-format="yyyy-MM-dd"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期">
                </el-date-picker>
              </el-col>
              <!--<el-col :span="6" style="padding-left: 50px;">-->
              <!--  <el-select-->
              <!--      style="width: 150px !important;"-->
              <!--      clearable-->
              <!--      v-model="drivers_type"-->
              <!--      placeholder="请选择"-->
              <!--      class="ele-fluid">-->
              <!--    <el-option-->
              <!--        v-for="(item) in options"-->
              <!--        :label="item.label"-->
              <!--        :value="item.value"-->
              <!--    />-->
              <!--  </el-select>-->
              <!--</el-col>-->
            </el-row>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="15" style="margin-top: 20px;display: flex;align-items: center;">
        <el-col :span="4">
          <div style="font-size: 18px;text-align: center;">{{shuliang}}个订单</div>
          <div style="font-size: 14px;text-align: center;">订单总数(包含已取消)</div>
        </el-col>
        <el-col :span="20">
          <echarts-order v-if="show" @total_quantity="querytotal_quantity" :channel_id="channel_id" :time="time"></echarts-order>
        </el-col>
      </el-row>
    </el-card>

    <el-row :gutter="15">
      <el-col :span="12">
        <el-card shadow="never" style="margin-top: 20px;">
          <div style="display: flex;align-items: center;">
            <div class='xiaoshudao'></div>
            <div>
            <span class='titlestyle'>过去六个月已完成的订单数量</span>
            </div>
          </div>
          <div>
            <former-lyorder></former-lyorder>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card shadow="never" style="margin-top: 20px;">
          <div style="display: flex;align-items: center;justify-content: space-between;">
            <div style="display:flex;align-items: center;">
              <div class='xiaoshudao'></div>
              <div>
                <span class="titlestyle">各救援比例(已完成) {{zhanbi}}个订单</span>
              </div>
            </div>
            <div>
              <el-date-picker
                  @change="selecttime1"
                  unlink-panels
                  v-model="time1"
                  type="daterange"
                  placeholder="请选择"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期">
              </el-date-picker>
            </div>
          </div>
          <div>
            <type-proportion v-if="show1"@total_quantity="querytotal_quantity1" :time1="time1"></type-proportion>
          </div>
        </el-card>
      </el-col>
    </el-row>

    <el-row :gutter="15">
      <el-col :span="12">
        <el-card shadow="never" style="margin-top: 20px;">
          <div style="display: flex;align-items: center;">
            <div class='xiaoshudao'></div>
            <div>
            <span class='titlestyle'>自营司机({{ziyingcount}}个司机)</span>
            </div>
          </div>
          <div>
            <autotrophy-technician @count="querycount"></autotrophy-technician>
          </div>
        </el-card>
      </el-col>
      <el-col :span="12">
        <el-card shadow="never" style="margin-top: 20px;">
          <div style="display: flex;align-items: center;">
            <div class='xiaoshudao'></div>
            <div>
            <span class='titlestyle'>共享司机({{gxcount}}个司机)</span>
            </div>
          </div>
          <div>
            <share-technician @sharecount="querysharecount"></share-technician>
          </div>
        </el-card>
      </el-col>
    </el-row>

  </div>
</template>

<script>
//常用功能入口
import gridMenu from './components/service_gridMenu';
// 订单数量echarts
import echartsFrom from '@/components/echarts/index';
// 订单金额echarts
import echartsFr from '@/components/echarts/moneyindex';
// 订单数量汇总echarts
import echartsOrder from '@/components/echarts/ordernumber';
// 自营司机echarts
import autotrophyTechnician from '@/components/echarts/autotrophytechnician';
// 各救援比例echarts
import typeProportion from '@/components/echarts/typeproportion';
// 共享司机echarts
import shareTechnician from '@/components/echarts/sharetechnician';
// 过去六个月已完成订单数量echarts
import formerLyorder from '@/components/echarts/formerlyorder';


// 引入的接口
import {
  facilitatorfirm_data_statics,
  facilitatorheader_statics,
  facilitatororder_count_charts,
  facilitatororder_data_statics, facilitatororder_money_statics, member_info
} from "@/api/statistics";
import {get_select} from "@/api/custom";

export default {
  name: 'index',
  data () {
    return {
      // 头部统计
      header_statics:{},
      //订单数据总览
      order_data_statics:{},
      //企业数据总览
      firm_data_statics:{},
      //订单数据总览
      order_count_charts:{},
      //订单金额汇总
      order_money_statics:{},

      grid: {
        xl: 6,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 24,
      },
      grid1:{
        xl: 6,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 24,
      },
      grid10:{
        xl: 12,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 24,
      },



      ordergrid_l:{
        xl: 4,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 24,
      },
      ordergrid_r:{
        xl: 20,
        lg: 12,
        md: 12,
        sm: 12,
        xs: 24,
      },

      //tab
      isShow:1,
      tab:[
        {
          name:'今日',
          value:'today'
        },
        {
          name:'本周',
          value:'week'
        },
        {
          name:'本月',
          value:'month'
        }
      ],
      cycle:'week',

      moneyisShow:1,
      moneytab:[
        {
          name:'今日',
          value:'today'
        },
        {
          name:'本周',
          value:'week'
        },
        {
          name:'本月',
          value:'month'
        }
      ],
      cycle1:'week',

      //echarts 订单数量
      line: 'line',
      infoList: {
        xAxis: [
          // "07-9",
          // "07-10",
          // "07-11",
          // "07-12",
          // "07-13",
          // "07-14",
        ],
        series: [
          // 18,
          // 39,
          // 60,
          // 48,
          // 40,
          // 46
        ]
      },
      series: [],

      // 订单金额统计
      infoListse:{
        xAxis: [
          // "07-9",
          // "07-10",
          // "07-11",
          // "07-12",
          // "07-13",
          // "07-14",
        ],
        series: [
          // 18,
          // 39,
          // 60,
          // 48,
          // 40,
          // 46
        ]
      },
      series1:[],

      //会员信息
      memberinfo:{},

      //客户
      channel_id: null,
      //客户列表
      client:[],
      //时间
      time:[],
      //今日
      drivers_type:'',
      options:[
        {
          label:'今天',
          value:''
        },
        {
          label:'昨天',
          value:'0'
        },
        {
          label:'本周',
          value:'1'
        },
        {
          label:'本月',
          value:'2'
        },
      ],
      //订单数量汇总
      show:false,

      //各救援比例
      show1:false,
      time1:[],

      //自营司机数量
      ziyingcount:'',
      //共享司机数量
      gxcount:'',
      //订单数量汇总
      shuliang:'',
      //占比
      zhanbi:'',


    }
  },
  components: {
    //常用功能入口
    gridMenu,
    //订单数量echarts
    echartsFrom,
    //订单金额echarts
    echartsFr,
    //订单数量汇总
    echartsOrder,
    //自营司机
    autotrophyTechnician,
    //各救援比例
    typeProportion,
    //共享司机
    shareTechnician,
    //过去六个月已完成订单
    formerLyorder
  },
  // 支柱道具属性
  props: {},
  // 计算属性 类似于 data 概念
  computed: {
    // 当前登录用户信息
    loginUser () {
      return this.$store.state.admin.userInfo
    },
  },
  // 监控 data 中的数据变化
  watch: {
    '$route':'getPath'
  },
  // 生命周期 - 创建完成（可以访问当前this 实例）
  created () {
  },
  // 生命周期 - 挂载完成（可以访问 DOM 元素）
  mounted () {
    // this.getStatistics();
    this.getPath()
  },

  // 方法集合
  methods: {
    getPath(){
      console.log(this.$route.path),'路由';
      if (this.$route.path == '/home/service_index') {
        this.getfacilitatorheader_statics();
        this.getfacilitatororder_data_statics();
        this.getfacilitatorfirm_data_statics();
        this.getorder_count_charts();
        this.getorder_money_statics();

        this.getmember_info();

        //获取客户
        this.getKehu();
        //显示订单数量汇总
        this.show = true;
        this.show1 = true;
      }
    },


    //获取客户名称列表
    getKehu(){
      get_select().then(res => {
        this.client = res.data;
      })
    },
    //选择客户
    selectclient(value){
      console.log(value)
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      })
    },

    //选择日期范围
    selecttime(value){
      this.show = false;
      this.$nextTick(() => {
        this.show = true;
      })
    },

    //各救援比例选择日期范围
    selecttime1(value){
      this.show1 = false;
      this.$nextTick(() => {
        this.show1 = true;
      })
    },

    //获取自营司机数量
    querycount(value){
      this.ziyingcount = value;
    },
    //获取共享司机数量
    querysharecount(value){
      this.gxcount = value;
    },
    //获取订单数量统计数量
    querytotal_quantity(value){
      this.shuliang = value;
    },
    // 各救援项目比例占比
    querytotal_quantity1(value){
      this.zhanbi = value;
    },

    // 点击tab
    change(item,index){
      console.log(item)
      this.isShow = index;
      this.cycle = item.value;
      this.getorder_count_charts();
    },
    moneychange(item,index){
      console.log(item)
      this.moneyisShow = index;
      this.cycle1 = item.value;
      this.getorder_money_statics();
    },

    //获取会员身份
    getmember_info(){
      member_info().then(res => {
        this.memberinfo = res.data;
      })
    },

    //获取统计数据接口
    getfacilitatorheader_statics(){
      facilitatorheader_statics().then(res => {
        this.header_statics = res.data;
      })
    },
    //获取订单数据总览接口
    getfacilitatororder_data_statics(){
      facilitatororder_data_statics().then(res => {
        this.order_data_statics = res.data;
      })
    },
    //获取企业数据总览
    getfacilitatorfirm_data_statics(){
      facilitatorfirm_data_statics().then(res => {
        this.firm_data_statics = res.data;
      })
    },

    //获取订单数据量汇总
    getorder_count_charts(){
      facilitatororder_count_charts({
        cycle: this.cycle
      }).then(res => {
        // console.log(res)
        this.order_count_charts = res.data;
        this.infoList.xAxis = this.order_count_charts.echartsData.xAxis;
        //模拟数据
        let ser = this.order_count_charts.echartsData.series;
        this.series = [
          {
            data: ser,
            name: '订单数',
            type: 'line',
            tooltip: true,
            smooth: true,
            symbol: 'none',
            areaStyle: {
              normal: {
                opacity: 0.2,
              },
            },
          },
        ];
      })
    },

    //获取订单金额汇总
    getorder_money_statics(){
      facilitatororder_money_statics({
        cycle: this.cycle1
      }).then(res => {
        console.log(res)
        this.order_money_statics = res.data;
        this.infoListse.xAxis = this.order_money_statics.echartsData.xAxis;
        //模拟数据
        let ser = this.order_money_statics.echartsData.series;
        this.series1 = [
          {
            data: ser,
            name: '金额',
            type: 'line',
            tooltip: true,
            smooth: true,
            symbol: 'none',
            areaStyle: {
              normal: {
                opacity: 0.2,
              },
            },
          },
        ];
      })
    },


    // 统计
    getStatistics() {
      // userApi()
      //   .then(async (res) => {
      //     this.infoList = res.data;
      //     this.series = [
      //       {
      //         data: res.data.series,
      //         name: '人数（人）',
      //         type: 'line',
      //         tooltip: true,
      //         smooth: true,
      //         symbol: 'none',
      //         areaStyle: {
      //           normal: {
      //             opacity: 0.2,
      //           },
      //         },
      //       },
      //     ];
      //     this.bing_data = res.bing_data;
      //     this.bing_xdata = res.bing_xdata;
      //   })
      //   .catch((res) => {
      //     this.$Message.error(res.msg);
      //   });

      //模拟数据
      let ser = [
        0,
        18,
        1,
        39,
        2,
        60,
        3
      ]
      this.series = [
        {
          data: ser,
          name: '人数（人）',
          type: 'line',
          tooltip: true,
          smooth: true,
          symbol: 'none',
          areaStyle: {
            normal: {
              opacity: 0.2,
            },
          },
        },
      ];
    },

    // 监听页面宽度变化，刷新表格
    handleResize() {
      if (this.infoList && this.series.length !== 0) this.$refs.userChart.handleResize();
    },

    //跳转到开通会员页面
    ToVip(){
      this.$router.push({path: '/upgradeMember'})
    },

  },
  // 生命周期 - 更新之前
  beforeUpdate () {
  },
  // 生命周期 - 更新之后
  updated () {
  },
  // 过滤器
  filters: {},
  // 生命周期 - 创建之前
  beforeCreate () {
  },
  // 生命周期 - 挂载之前
  beforeMount () {
  },
  // 生命周期 - 销毁之前
  beforeDestroy () {
  },
  // 生命周期 - 销毁完成
  destroyed () {
  },
  // 如果页面有 keep-alive 缓存功能,这个函数会触发
  // 进入的时候触发
  activated () {
  },
  // 离开的时候触发
  deactivated () {
  }
}
</script>

<style scoped lang="scss">
.title{
  font-size: 24px;
  font-weight: 500;
  color: #707070;
  margin-bottom: 20px;
}
.contentshouye{
  width: 100%;
}
// 解决高度坍塌
.content_kshouye:after{
  content: '';
  clear: both;
  display: block;
}
.content_kshouye{
  //鼠标变小手
  cursor: pointer;
  width: 100%;
  padding: 15px;
  background: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(230,230,230,0.88);
  .content_kshouye_img{
    width: 45px;
    height: 41px;
    float: left;
    vertical-align: middle;
    margin-top: 10px;
  }
  .content_kshouye_div{
    //width: 80%;
    float: left;
    vertical-align: middle;
    margin-left: 18px;
  }
  .content_kshouye_div_f1{
    font-weight: 500;
    text-align: left;
    color: #3f4155;
  }
  .wenhaoimg{
    display: inline-block;
    vertical-align: middle;
    width: 13px;
    height: 13px;
    margin-left: 15px;
    background-image: url("../../assets/images/home/icon/wenhao.png");
  }
  .content_kshouye_div_f2{
    font-size: 30px;
    font-weight: 700;
    text-align: left;
    color: #3f4155;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }
  .content_kshouye_div_f2_f{
    font-size: 12px;
    font-weight: 400;
    color: #69726e;
    margin-left: 30px;
  }
}
.content_kshouye:hover{
  background: #eff2ff;
  border: 1px solid #5976e1;
  border-radius: 4px;
  box-shadow: 0px 0px 10px 0px rgba(89,118,225,0.25);
}
.content_kshouye:hover .wenhaoimg{
  background-image: url("../../assets/images/home/servicehome/wenhao.png");
}

//总览样式
.Overview{
  //margin-top: 40px;
  .Overview_t{
    display: flex;
    align-items: center;
  }
  .Overview_t_icon{
    width: 5px;
    height: 20px;
    background: #ff9b05;
    display: inline-block;
  }
  .Overview_t_text{
    font-size: 18px;
    font-weight: 700;
    color: #202033;
    display: inline-block;
    margin-left: 10px;
  }
  .Overview_c{
    width: 100%;
    padding: 30px 20px 30px 20px;
    border: 1px solid #e9eaf1;
    margin-top: 20px;
  }
  .Overview_c_k{
    //width: 25%;
    text-align: center;
    //display: inline-block;
  }
  .Overview_c_k_f1{
    font-size: 14px;
    font-weight: 400;
    color: #868792;
  }
  .Overview_c_k_f2{
    font-size: 30px;
    font-weight: 700;
    color: #3f4155;
  }
}

// 常用功能入口
.Commonfunctions{

  .Commonfunctions_t{
    display: flex;
    align-items: center;
  }
  .Commonfunctions_t_icon{
    width: 5px;
    height: 20px;
    background: #ff9b05;
    display: inline-block;
  }
  .Commonfunctions_t_text{
    font-size: 18px;
    font-weight: 700;
    color: #202033;
    display: inline-block;
    margin-left: 10px;
  }
  .Commonfunctions_c{
    width: 100%;
    //padding: 30px 20px 30px 20px;
    //border: 1px solid #e9eaf1;
    margin-top: 20px;
  }

}

// 常用功能入口
.order{
  .order_t{
    display: flex;
    align-items: center;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;
  }
  .order_t_icon{
    width: 5px;
    height: 20px;
    background: #ff9b05;
    display: inline-block;
  }
  .order_t_text{
    font-size: 18px;
    font-weight: 700;
    color: #202033;
    display: inline-block;
    margin-left: 10px;
  }
  .order_c{
    width: 100%;
    //padding: 30px 20px 30px 20px;
    //border: 1px solid #e9eaf1;
    margin-top: 20px;
  }

}
.anniu{
  //鼠标变小手
  cursor: pointer;
  margin-left: 20px;
  display: inline-block;
  .div{
    width: 60px;
    padding-top: 5px;
    padding-bottom: 5px;
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    color: #3f4155;
    border: 1px solid #c5c6cc;
  }
  .div:hover{
    background: #3f4155;
    color: #FFFFFF;
    border: 1px solid #3f4155;
  }
  // css选择器 第一个
  .div:first-child{
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px
  }
  //最后一个
  .div:last-child{
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px
  }
  .div1{
    background: #3f4155;
    color: #FFFFFF;
    border: 1px solid #3f4155;
  }
  .moneydiv{
    background: #3f4155;
    color: #FFFFFF;
    border: 1px solid #3f4155;
  }
}
.order_img{
  width: 220px;
  height: 200px;
  background-image: url("../../assets/images/home/shuliang.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  padding-left: 30px;
  margin: auto;
  margin-top: 45px;
  .order_img_t{
    font-size: 16px;
    font-weight: 500;
    color: #868792;
  }
  .order_img_s{
    font-size: 36px;
    font-weight: 700;
    color: #5976e1;
  }
}
.order_img1{
  width: 220px;
  height: 200px;
  background-image: url("../../assets/images/home/jine.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding-top: 50px;
  padding-left: 30px;
  margin: auto;
  margin-top: 45px;
  .order_img_t1{
    font-size: 16px;
    font-weight: 500;
    color: #868792;
  }
  .order_img_s1{
    font-size: 36px;
    font-weight: 700;
    color: #FF9B05;
  }
}


.titlestyle{
  font-size: 18px;
  font-weight: 700;
  color: #202033;
  display: inline-block;
  margin-left: 10px;
}
.xiaoshudao{
  width: 5px;height: 20px;background: #ff9b05;display: inline-block;
}
</style>
