<template>
  <!--各救援比例-->
  <div>
    <div :id="echarts" style="height: 300px"></div>
  </div>
</template>

<script>
import echarts from 'echarts';
import {order_proportion_statics} from '@/api/statistics'

export default {
  name: 'index',
  data() {
    return {
      infoLists: this.infoList,
      seriesArray: this.series,
    };
  },
  computed: {
    echarts() {
      return 'echarts' + Math.ceil(Math.random() * 100);
    },
  },
  mounted: function () {
    const vm = this;
    vm.$nextTick(() => {
      vm.handleSetVisitChart();
      window.addEventListener('resize', this.wsFunc);
    });
  },
  methods: {
    wsFunc() {
      this.myChart.resize();
    },
    handleSetVisitChart() {
      order_proportion_statics().then(res => {
        // console.log(res,'数据数据数据数据')
        this.$emit('total_quantity',res.data.total_quantity)
        this.myChart = echarts.init(document.getElementById(this.echarts));
        let option = null;
        option = {
          tooltip: {
            trigger: 'item'
          },
          legend: {
            orient: 'horizontal',
            top: 'bottom'
          },
          series: [
            {
              name: '比例',
              type: 'pie',
              radius: '50%',
              // data: [
              //   { value: 1048, name: 'Search Engine' },
              //   { value: 735, name: 'Direct' },
              //   { value: 580, name: 'Email' },
              //   { value: 484, name: 'Union Ads' },
              //   { value: 300, name: 'Video Ads' }
              // ],
              data: res.data.yAxis,
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: 'rgba(0, 0, 0, 0.5)'
                }
              }
            }
          ]
        };
        // 基于准备好的dom，初始化echarts实例
        this.myChart.setOption(option, true);
      })
    },
    handleResize() {
      this.myChart.resize();
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.wsFunc);
    if (!this.myChart) {
      return;
    }
    this.myChart.dispose();
    this.myChart = null;
  },
};
</script>

<style scoped></style>
