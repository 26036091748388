<template>
  <!--共享司机-->
  <div>
    <div :id="echarts" style="height: 300px"></div>
  </div>
</template>

<script>
import echarts from 'echarts';
import {share_driver_statics} from '@/api/statistics'

export default {
  name: 'index',
  data() {
    return {
      infoLists: this.infoList,
      seriesArray: this.series,
    };
  },
  computed: {
    echarts() {
      return 'echarts' + Math.ceil(Math.random() * 100);
    },
  },
  mounted: function () {
    const vm = this;
    vm.$nextTick(() => {
      vm.handleSetVisitChart();
      window.addEventListener('resize', this.wsFunc);
    });
  },
  methods: {
    wsFunc() {
      this.myChart.resize();
    },
    handleSetVisitChart() {
      share_driver_statics().then(res => {
        this.$emit('sharecount',res.data.count);
        this.myChart = echarts.init(document.getElementById(this.echarts));
        let option = null;
        option = {
          legend: {
            top: 'bottom'
          },
          // toolbox: {
          //   show: true,
          //   feature: {
          //     mark: { show: true },
          //     dataView: { show: true, readOnly: false },
          //     restore: { show: true },
          //     saveAsImage: { show: true }
          //   }
          // },
          series: [
            {
              name: '共享司机',
              type: 'pie',
              radius: '70%',
              center: ['50%', '50%'],
              roseType: 'area',
              itemStyle: {
                borderRadius: 8
              },
              // data: [
              //   {
              //     value: 40,
              //     name: 'rose 1',
              //     itemStyle: {
              //       color: '#5976E1'
              //     }
              //   },
              //   { value: 38, name: 'rose 2' },
              // ]
              data: res.data.echarts
            }
          ]
        };
        // 基于准备好的dom，初始化echarts实例
        this.myChart.setOption(option, true);
      })
    },
    handleResize() {
      this.myChart.resize();
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.wsFunc);
    if (!this.myChart) {
      return;
    }
    this.myChart.dispose();
    this.myChart = null;
  },
};
</script>

<style scoped></style>
