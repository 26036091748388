<template>
  <!--过去六个月已完成订单数量-->
  <div>
    <div :id="echarts" style="height: 300px"></div>
  </div>
</template>

<script>
import echarts from 'echarts';
import {order_past_june_statics} from '@/api/statistics'

export default {
  name: 'index',
  data() {
    return {
      infoLists: this.infoList,
      seriesArray: this.series,
    };
  },
  computed: {
    echarts() {
      return 'echarts' + Math.ceil(Math.random() * 100);
    },
  },
  mounted: function () {
    const vm = this;
    vm.$nextTick(() => {
      vm.handleSetVisitChart();
      window.addEventListener('resize', this.wsFunc);
    });
  },
  methods: {
    wsFunc() {
      this.myChart.resize();
    },
    handleSetVisitChart() {
      order_past_june_statics().then(res => {
        this.myChart = echarts.init(document.getElementById(this.echarts));
        let option = null;
        option = {
          color: ['#5976E1', '#00CC66', '#F9D249', '#ff9900', '#9860DF'],
          xAxis: {
            type: 'category',
            // data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
            data: res.data.xAxis
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              // data: [
              //   {
              //     value: 120,
              //     itemStyle: {
              //       color: '#5976E1'
              //     }
              //   },
              //   {
              //     value: 200,
              //     itemStyle: {
              //       color: '#00CC66'
              //     }
              //   },
              //   {
              //     value: 150,
              //     itemStyle: {
              //       color: '#F9D249'
              //     }
              //   },
              //   {
              //     value: 80,
              //     itemStyle: {
              //       color: '#ff9900'
              //     }
              //   },
              //   {
              //     value: 70,
              //     itemStyle: {
              //       color: '#9860DF'
              //     }
              //   },
              //   {
              //     value: 110,
              //     itemStyle: {
              //       color: '#a90000'
              //     }
              //   },
              //   {
              //     value: 130,
              //     itemStyle: {
              //       color: '#a90000'
              //     }
              //   },
              // ],
              data: res.data.yAxis,
              type: 'bar'
            }
          ]
        };
        // 基于准备好的dom，初始化echarts实例
        this.myChart.setOption(option, true);
      })
    },
    handleResize() {
      this.myChart.resize();
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.wsFunc);
    if (!this.myChart) {
      return;
    }
    this.myChart.dispose();
    this.myChart = null;
  },
};
</script>

<style scoped></style>
