import { render, staticRenderFns } from "./sharetechnician.vue?vue&type=template&id=119b15be&scoped=true&"
import script from "./sharetechnician.vue?vue&type=script&lang=js&"
export * from "./sharetechnician.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "119b15be",
  null
  
)

export default component.exports