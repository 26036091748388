<template>
  <Row :gutter="24" class="dashboard-console-grid">
    <Col v-bind="grid" class="ivu-mb">
      <Card :bordered="false">
        <router-link :to="{ path: '/humanResources/employeeManagement' }">
          <!--<Icon type="md-people" color="#69c0ff" />-->
          <img style="width: 94px; height: 94px;" src="@/assets/images/home/servicehome/gn1.png" alt="">
          <p>员工管理</p>
        </router-link>
      </Card>
    </Col>
    <Col v-bind="grid" class="ivu-mb">
      <Card :bordered="false">
        <router-link :to="{ path: '/yunli/serviceProvidersAndServiceProviders' }">
          <!--<Icon type="md-settings" color="#95de64" />-->
          <img style="width: 94px; height: 94px;" src="@/assets/images/home/servicehome/gn2.png" alt="">
          <p>服务商与服务商列表</p>
        </router-link>
      </Card>
    </Col>
    <!--<Col v-bind="grid" class="ivu-mb" v-auth="['admin-store-storeProuduct-index']">-->
    <!--  <Card :bordered="false">-->
    <!--    <router-link :to="{ path: $routeProStr + '/product/product_list' }">-->
    <!--      &lt;!&ndash;<Icon type="md-cart" color="#ff9c6e" />&ndash;&gt;-->
    <!--      <img style="width: 94px; height: 94px;" src="@/assets/images/home/servicehome/gn3.png" alt="">-->
    <!--      <p>接单设置</p>-->
    <!--    </router-link>-->
    <!--  </Card>-->
    <!--</Col>-->
    <!--<Col v-bind="grid" class="ivu-mb" v-auth="['admin-order-storeOrder-index']">-->
    <!--  <Card :bordered="false">-->
    <!--    <router-link :to="{ path: $routeProStr + '/order/list' }">-->
    <!--      &lt;!&ndash;<Icon type="md-clipboard" color="#b37feb" />&ndash;&gt;-->
    <!--      <img style="width: 94px; height: 94px;" src="@/assets/images/home/servicehome/gn4.png" alt="">-->
    <!--      <p>系统设置 </p>-->
    <!--    </router-link>-->
    <!--  </Card>-->
    <!--</Col>-->
    <Col v-bind="grid" class="ivu-mb">
      <Card :bordered="false">
        <router-link target="_blank" :to="{ path: '/largeScreen' }">
          <!--<Icon type="md-mail" color="#ffd666" />-->
          <img style="width: 94px; height: 94px;" src="@/assets/images/home/icon/gn5.png" alt="">
          <p>运营大屏</p>
        </router-link>
      </Card>
    </Col>
    <!--<Col v-bind="grid" class="ivu-mb">-->
    <!--  <Card :bordered="false">-->
    <!--    <router-link :to="{ path: '/commodityPurchase' }">-->
    <!--      &lt;!&ndash;<Icon type="md-card" color="#5cdbd3" />&ndash;&gt;-->
    <!--      <img style="width: 94px; height: 94px;" src="@/assets/images/home/servicehome/gn6.png" alt="">-->
    <!--      <p>购买服务套餐</p>-->
    <!--    </router-link>-->
    <!--  </Card>-->
    <!--</Col>-->
  </Row>
</template>
<script>
export default {
  data() {
    return {
      grid: {
        xl: 4,
        lg: 6,
        md: 6,
        sm: 8,
        xs: 8,
      },
    };
  },
};
</script>
<style lang="less">
.dashboard-console-grid {
  text-align: center;
  .ivu-card-body {
    padding: 0;
  }
  i {
    font-size: 32px;
  }
  a {
    display: block;
    color: inherit;
    padding: 16px;
  }
  p {
    margin-top: 8px;
  }
}
</style>
